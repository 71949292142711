import styled from 'styled-components';
import media from 'styles/media';

export default styled.div`
  display: inline-block;
  position: relative;
  margin: 5rem 0 5rem;
  height: auto;
  width: 100%;

  section {
    margin: 0 0 2rem;
  }

  ${media.xs`
    margin: 0;
  `};
`;