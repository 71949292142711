import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import Quote from 'components/Quote';
import Wrapper from './styled.js';

const QuoteBlock = ({ ...quoteProps }) => (
  <Wrapper>
    <Outer>
      <Inner>
        <section>
          <Row center="xs">
            <Col xs={11} sm={11} md={7}>
              <Quote {...quoteProps} />
            </Col>
          </Row>
        </section>
      </Inner>
    </Outer>
  </Wrapper>
);

export default QuoteBlock;
