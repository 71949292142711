import React from 'react';
import { theme } from 'config/employment.json';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import QuoteBlock from 'modules/Quote';
import CalloutStampBlock from 'modules/CalloutStamp';
import CalloutHeroBlock from 'modules/CalloutHero';
import Conclusion from 'modules/Conclusion';
import InfoCollage from 'modules/InfoCollage';
import Videos from 'modules/Videos';
import InfoParallax from 'modules/InfoParallax';
import PhotoGridBlock from 'blocks/employment/Block-4-PhotoGrid';
import Noise from 'components/Sound/Noise';

const Employment = () => (
  <Template theme={theme}>
    <main>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            md: 8,
            mdOffset: -1
          },
          layers: [
            'employment/apprenticeship-1',
            'employment/apprenticeship-2',
            'employment/apprenticeship-3'
          ],
          caption: {
            message: 'employment_imageLocalyouthsCaption',
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2
            }
          }
        }}
        infoBoxes={[
          {
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2,
              md: 5,
              mdOffset: 6,
              lg: 6,
              lgOffset: 6
            },
            message: 'employment_infoEmppromotBody'
          }
        ]}
        voffset={{
          sm: '25rem 0 5rem'
        }}
      />
      <QuoteBlock
        quoteID="employment_quoteSantosBody"
        captionID="employment_quoteSantosCaption"
        soundID="employment/quote-juanmanuel"
        imageID="employment/juan-manuel"
        imageWidth="250px"
      />
      <Noise id="employment/rubbish-crowd" volume={90}>
        <InfoParallax
          figure={{
            colProps: {
              xs: 12,
              sm: 9,
              smOffset: 4,
              md: 9,
              mdOffset: 3
            },
            image: 'employment/end-poverty',
            caption: 'employment_imageWastepickCaption'
          }}
          infoBox={{
            infoBox: {
              scroll: true,
              size: {
                lg: '28rem',
                md: '23rem',
                sm: '20rem'
              }
            },
            colProps: {
              xs: 12,
              sm: 7,
              mdOffset: 0,
              md: 5
            },
            message: 'employment_infoEndpoverty',
            justify: {
              lg: 'center',
              md: 'center',
              sm: 'flex-start'
            }
          }}
          voffset={{
            lg: '15rem 0 5rem',
            sm: '15rem 0 0',
            xs: '0'
          }}
        />
      </Noise>
      <PhotoGridBlock />
      <CalloutHeroBlock
        imageId="employment/workers-bangladesh"
        calloutId="employment_calloutDecentjobsTitle"
        outerHeight={{
          lg: 'auto',
          md: 'auto',
          sm: 'auto',
          xs: 'initial'
        }}
      />
      <CalloutStampBlock
        stamp="pinkcircle"
        calloutId="employment_calloutDecentjobsBody"
      />
      <Videos videos="employment/videoCardona" />
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 3,
            md: 8,
            mdOffset: 0
          },
          image: 'employment/hotel-recep',
          caption: 'employment_imageApprenticeCaption'
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '28rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 6,
            md: 5,
            mdOffset: 6
          },
          message: 'employment_infoRightskills',
          justify: 'flex-start'
        }}
        voffset={{
          lg: '15rem 0 5rem',
          md: '10rem 0 0',
          sm: '15rem 0 5rem',
          xs: '0'
        }}
      />
      <Videos videos="employment/videoAuneme" />
      <Noise
        id="employment/construction-site"
        volume={90}
        visibilitySensorProps={{ minTopValue: 500 }}
      >
        <InfoCollage
          voffset={{
            lg: '25rem 0 10rem',
            md: '20rem 0 10rem',
            sm: '25rem 0 0',
            xs: '5rem 0 0'
          }}
          collage={{
            colProps: {
              xs: 12,
              md: 10,
              mdOffset: 1
            },
            layers: [
              'employment/construction-1',
              'employment/construction-2',
              'employment/construction-3'
            ],
            caption: {
              message: 'employment_imageConstructionCaption',
              colProps: {
                xs: 12,
                smOffset: 3,
                sm: 6,
                md: 6,
                mdOffset: 6
              },
              voffset: {
                lg: '3rem',
                md: '3rem',
                sm: '0'
              }
            }
          }}
          infoBoxes={[
            {
              colProps: {
                xs: 12,
                sm: 8,
                smOffset: 2,
                md: 5,
                mdOffset: 0
              },
              message: 'employment_infoStronginstBody',
              justify: 'flex-end',
              position: {
                sm: 'relative'
              }
            },
            {
              infoBox: {
                scroll: true,
                size: { lg: '30rem', md: '22rem' }
              },
              colProps: {
                xs: 12,
                sm: 8,
                smOffset: 2,
                md: 5,
                mdOffset: 6,
                lg: 6
              },
              message: 'employment_infoInvestments',
              justify: 'flex-start'
            }
          ]}
        />
      </Noise>
      <CalloutStampBlock
        stamp="bluecircle"
        calloutId="employment_calloutThisiswhy"
      />
      <Noise id="employment/woodwork" volume={100}>
        <InfoCollage
          voffset={{
            lg: '0 0 8rem',
            md: '0 0 6rem',
            sm: '0 0 6rem'
          }}
          collage={{
            colProps: {
              xs: 12,
              smOffset: -2,
              mdOffset: -2
            },
            layers: [
              'employment/wood-1',
              'employment/wood-2',
              'employment/wood-3'
            ],
            caption: {
              message: 'employment_imageTraineesCaption',
              colProps: {
                xs: 12,
                sm: 5,
                smOffset: 2
              },
              voffset: {
                lg: '2rem'
              }
            }
          }}
          infoBoxes={[
            {
              infoBox: {
                scroll: true,
                size: {
                  lg: '23rem',
                  sm: '18rem'
                }
              },
              colProps: {
                xs: 12,
                sm: 6,
                smOffset: 5,
                md: 5,
                mdOffset: 6,
                lg: 6
              },
              message: 'employment_infoNepalBody1',
              justify: 'flex-end'
            }
          ]}
        />
      </Noise>
      <Conclusion
        mainTextId="employment_calloutChallengesdwTitle"
        subTextId="employment_calloutChallengesdwBody"
      />
    </main>
  </Template>
);

export default withIntl(Employment);
