import styled from 'styled-components';
import media from 'styles/media';

export default styled.section`
  display: inline-block;
  width: 100%;
  height: auto;
  margin: 5rem 0;

  ${media.xs`
    height: auto;
    margin: 1rem 0;
  `}
`