import React from 'react';
import PhotoGrid from 'modules/PhotoGrid';
import { injectIntl } from 'react-intl';
import getContent from 'utils/getContentBySubstring';
import CalloutStampBlock from 'modules/CalloutStamp';
import Wrapper from './styled';

const Block = ({ intl: { messages } }) => (
  <Wrapper>
    <CalloutStampBlock
      stamp="fingerprint"
      calloutId="employment_calloutWhatstandsTitle"
      subtextId="employment_calloutWhatstandsBody"
    />
    <PhotoGrid
      items={[
        {
          image: 'employment/inequality',
          name: 'employment_detailInequalityTitle',
          detail: {
            messages: getContent(messages, 'employment_detailInequalityBody'),
            figureProps: {
              image: 'employment/inequality',
              caption: 'employment_imageSlumsCaption'
            }
          }
        },
        {
          image: 'employment/conflict',
          name: 'employment_detailConflictTitle',
          detail: {
            messages: getContent(messages, 'employment_detailConflictBody'),
            figureProps: {
              image: 'employment/conflict',
              caption: 'employment_imageTaclobanCaption'
            }
          }
        },
        {
          image: 'employment/youth',
          name: 'employment_detailYouthempTitle',
          detail: {
            messages: getContent(messages, 'employment_detailYouthempBody'),
            figureProps: {
              image: 'employment/youth',
              caption: 'employment_imageMigrantaddisCaption'
            }
          }
        },
        {
          image: 'employment/rural',
          name: 'employment_detailRuralexodusTitle',
          detail: {
            messages: getContent(messages, 'employment_detailRuralexBody'),
            figureProps: {
              image: 'employment/rural',
              caption: 'employment_imageFarmersCaption'
            }
          }
        },
        {
          image: 'employment/informal',
          name: 'employment_detailInformalTitle',
          detail: {
            messages: getContent(messages, 'employment_detailInformalBody'),
            figureProps: {
              image: 'employment/informal',
              caption: 'employment_imageHandicraftCaption'
            }
          }
        },
        {
          image: 'employment/migration',
          name: 'employment_detailMigrationTitle',
          detail: {
            messages: getContent(messages, 'employment_detailMigrationBody'),
            figureProps: {
              image: 'employment/migration',
              caption: 'employment_imageMigrantdatesCaption'
            }
          }
        }
      ]}
    />
  </Wrapper>
);

export default injectIntl(Block);
